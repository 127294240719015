import React from 'react';
import ReactDOM from 'react-dom'; // react-dom/client yerine react-dom import edilecek
import './index.css'; // Varsa genel stiller
import App from './App';
import reportWebVitals from './reportWebVitals';

// React uygulamasını tamamen devre dışı bırakıyoruz
// Ana sayfaya yönlendirme

// const root = ReactDOM.createRoot(document.getElementById('root')); // React 18 kodu yorum satırı yapıldı
// root.render(                                                    // React 18 kodu yorum satırı yapıldı
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );                                                               // React 18 kodu yorum satırı yapıldı

ReactDOM.render( // React 17 için ReactDOM.render kullanılıyor
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals//
reportWebVitals();
