import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../styles/header.css';
import logo from '../styles/acm-logoo.png';

function Header() {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <header>
      <Link to="/"><img src={logo} alt="ACM Iğdır Logo" /></Link>
      <nav>
        <ul>
          <li><Link to="/">Ana Sayfa</Link></li>
          <li><Link to="/acm">ACM Nedir?</Link></li>
          <li><Link to="/etkinlikler">Etkinlikler</Link></li>
          <li><Link to="/iletisim">İletişim</Link></li>
          <li><Link to="/duyurular">Duyurular</Link></li>
          <li><Link to="/uye-ol" className="register-btn">Üye Ol</Link></li>
        </ul>
      </nav>
      <button className="burger-menu" onClick={toggleMenu}>
        &#9776;
      </button>
      {menuOpen && (
        <div className="mobile-nav">
          <Link to="/">Ana Sayfa</Link>
          <Link to="/acm">ACM Nedir?</Link>
          <Link to="/etkinlikler">Etkinlikler</Link>
          <Link to="/iletisim">İletişim</Link>
          <Link to="/duyurular">Duyurular</Link>
          <Link to="/uye-ol" className="register-btn">Üye Ol</Link>
        </div>
      )}
    </header>
  );
}

export default Header;
